import React from 'react'
import  Calculator  from './components/calculator'
import  Header  from './components/header'
import  Footer  from './components/footer'
import  { CalculationProvider } from './components/calculationProvider';
import AlertDialog from './components/alertDialog'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTranslation } from "react-i18next";
import ErrorBoundary from './components/errorBoundary';
import Message from './components/message'
import './App.css';

function App() {
  const { t } = useTranslation();
  return (
    <ErrorBoundary>
    <CalculationProvider>
      <AlertDialog/>
      <Message/>
      <div className="app">
        <Header/>
        <HelmetProvider>
          <Helmet>
            <title>{t("app_name")}</title>
          </Helmet>
        </HelmetProvider>
        <div className="container">
          <Calculator/>
          <Footer />
        </div>   
      </div>
  </CalculationProvider>
  </ErrorBoundary>
  );
}

export default App;
