import React from 'react'
import Select from '@mui/material/Select';
import { useTranslation } from "react-i18next";
import  { CalculationContext } from './calculationProvider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import * as constants from './constants';
import * as functions from './functions';

export default function DropDown(props) {

    const { t } = useTranslation();
  const context = React.useContext(CalculationContext);
  const calculation = context.calculation;

    const handleValueChange = (e) => {
        context.setData([props.name, e.target.value], calculation);
    }

  const getValues = () => {
    var result = [];
    if (props.name === "year" || props.name === "comparison_year") {
      var currentYear = new Date().getFullYear();
      [...Array(5)].map((elementInArray, index) => ( result.push( {value: currentYear-index, key:""+(currentYear-index), name: ""+(currentYear-index) })));
    }
    else {
      var enumObject = props.name === "subsidy_area" ? constants.subsidyArea : constants.plants;
      for (const prop in enumObject)
        result.push({value: enumObject[prop], key: prop, name: t(prop)});
    }
    return result;
  }
  
   var values = getValues();
    return (
         <FormControl >             
            <Select
              id={"drop-down-"+props.name}
              displayEmpty
              renderValue={value => value !== '' && values.find(v => v.value === value) !== undefined ? values.find(v => v.value === value).name : t("select")}
              value={props.name === "comparison_year" && (calculation.comparison_year === null || values.find(v => v.value === calculation.comparison_year) === undefined) ? '': calculation[props.name]}
              onChange={e => functions.functionErrorBoundary(e, handleValueChange)}
              variant="outlined">
             {values.map((row, i) => (  <MenuItem key={"menuitem-"+row.key} value={row.value}>{row.name}</MenuItem>))}
            </Select>         
          </FormControl>
    );

}
