import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// eslint-disable-next-line no-unused-vars 
import i18n from "./i18n"; // TK: this is mandatory 


const theme = createTheme ({
  palette: {
    primary: {
      main: '#009639'
    }
  }
});


const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement);

root.render(
   <ThemeProvider theme={theme}>
      <App />
   </ThemeProvider>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
