export const BatchType = {
    seed: 1,
    fertilizer: 2,
    pesticide: 3,
    other: 4
}

export const subsidyArea = {
    subsidy_a: 1,
    subsidy_c: 2
}

export const plants = {
    winter_wheat: 1,
    spring_wheat: 2,
    rye: 3, 
    feed_barley: 4, 
    malting_barley: 5, 
    oat: 6, 
    turnip_rape_A: 7, 
    turnip_rape_S: 8, 
    rape_A: 9, 
    rape_S: 10,
    horse_bean: 11,
    pea: 12
}

export const plantMapping = {
    
    1: {plantIds: [11100], product: null},
    2:  {plantIds: [11200], product: null},
    3:  {plantIds: [12300], product: null}, 
    4:  {plantIds: [13100], product: 2121}, 
    5:  {plantIds: [13200], product: 2123},
    6:  {plantIds: [14100], product: null},
    7:  {plantIds: [41200], product: null},
    8:  {plantIds: [41100], product: null},
    9:  {plantIds: [42200], product: null},
    10:  {plantIds: [42100], product: null},
    11:  {plantIds: [22000], product: null},
    12:  {plantIds: [21200], product: null},

}

export const canShowData = 
{
    No:  0,
    OnlyShowTotal:1,
    Yes:2
}


export const defaultBatch = {
    id:null,
    name: "",
    isDefault: false,
    cost_type: null,
    price: null,
    amount: null,
    price_per_hectare: null,
    generateName: true,
    batchNr: 1
}

export const defaultBatches = [
    Object.assign({}, defaultBatch, {id:1, name: "fuels", isDefault: true, cost_type: BatchType.other} ),
    Object.assign({}, defaultBatch, {id:2, name: "drying", isDefault: true, cost_type: BatchType.other} ),
    Object.assign({}, defaultBatch, {id:3, name: "other", isDefault: true, cost_type: BatchType.other} ),

    Object.assign({}, defaultBatch, {id:4, cost_type: BatchType.seed}),
    Object.assign({}, defaultBatch, {id:5, cost_type: BatchType.fertilizer}),
    Object.assign({}, defaultBatch, {id:6, cost_type: BatchType.pesticide}),

    ];

export const defaultCalc = {
    plant: 1,                           // kasvi
    harvest_target: null,                  // Satotavoite
    year: new Date().getFullYear(),           // laskentavuosi
    comparison_plant: 1,                    // Vertailukasvi
    comparison_year: null,                  // Vertailuvuosi
    est_price: null,                       // Arviotu sadon hinta/sopimusthinta
    harvest_profit: null,                  // Satotuotto
    subsidy_area: 1,                    // Tukialue
    subsidy: null,                         // Perustuki
    compensation_nature: null,             // Luonnonhaittakorvaus
    compensation_environment: null,        // Ympäristökorvaus
    compensation_plant: null,              // Peltokasvipalkkio
    profit_sum: null,                     // satotuottojen ja tukien summa
    subsidy_sum:null,                     // tuet ja palkkiot, summa
    seeds_sum:null,                       // siemenet, summa
    fertilizer_sum:null,                  // lannoitteet, summa
    pesticide_sum:null,                   // kasvinsuojeluaineet, summa
    other_sum:null,                       // muut kustannukset, summa
    compensation:null,                  // Työlle ja kiinteille kuluille jäävä korvaus
    other_cost_total:null,               // Muuttuvat kustannukset yhteensä e/ha
    costBatches: defaultBatches
}

export const comparisonMapping = {
    "fertilizer_sum":"fertilizationCost",
    "pesticide_sum":"plantProtectionCost",
    "other_cost_total":"totalVariableCost",
    "harvest_target":"harvestAmount"
}

