import React from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useTranslation } from "react-i18next";

function LanguageSelect() {
  const { i18n } = useTranslation();

  return (
    
      <FormControl style={{ float: "right" }}>
            <Select
              id="drop-down-language"
              value={i18n.language}
              onChange={(e) =>
                i18n.changeLanguage(e.target.value)
              }
              variant="outlined">

             <MenuItem  value="fi">suomi</MenuItem>  
             <MenuItem  value="sv">svenska</MenuItem>  
            </Select>
      </FormControl>  
  );
}
export default LanguageSelect;