import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import NumberInput from './numberInput';
import { useTranslation } from "react-i18next";
import  { CalculationContext } from './calculationProvider';
import * as constants from './constants';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import TextField from '@mui/material/TextField';
import * as functions from './functions';
import DropDown from './dropDown';
import TableHead from '@mui/material/TableHead';
import InfoIcon from '@mui/icons-material/Info';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

/*Example data: {row: "compensation_environment", text: "info_evironment", index:0} */
const infos = [];
      

export default function DataTable(props) {

    const { t } = useTranslation();
    const context = React.useContext(CalculationContext);
    const calculation = context.calculation;
    const [showInfo, setShowInfo] = useState([false, false]);
 
    
    const customColumnStyle1 =   { width:  "180px"};
    const customColumnStyle2 =   { width:  "60px"};
    const customColumnStyle3 =   { width:  "145px"};

    const handleIconClicks = (event, index) => {
        event.stopPropagation();     
        var val = showInfo;
        val[index] = !val[index];
        setShowInfo([val[0], val[1]]);
    }

    // Rivin solut
    const renderRow = (index) => {

        var rowVal = props.tableData.rows[index];
        var rowName = props.tableData.rowNames ? props.tableData.rowNames[index] : rowVal;
        var unitText = props.tableData.units ? t(props.tableData.units[index]): "";
        var className = "";
        // Syötekenttä
        var cellContent =(<TableCell align="right"><NumberInput value={calculation[rowVal]} name={rowName} onBlur={(e) =>context.setData(e, calculation)}/></TableCell>);
        // Kasville pudotusvalikko
        if (rowName === "plant")
            cellContent =(<React.Fragment><TableCell align="right"><DropDown name="plant"/></TableCell><TableCell></TableCell></React.Fragment>);
        // Tukialueelle pudotusvalikko
        if (rowName === "subsidy_area")
            cellContent =(<TableCell align="right"  ><DropDown name="subsidy_area"/></TableCell>); 
        // Yhteensä-rivi tai satotuotto      
        if (rowName === "harvest_profit" || rowName === "sum" ) {
            cellContent =(<TableCell align="right" >{functions.numberFormat(calculation[rowVal])}</TableCell>);
            className = "sum-row";
        }
        // Tuotot-osiossa tiedot tekstinä eikä syötekenttänä
        if (props.tableData.tableName === "results") {
            cellContent =(<React.Fragment>
                   <TableCell align="right" >{functions.getEuroPerTon(calculation[rowVal], calculation)}</TableCell>
                 <TableCell align="right" >{functions.numberFormat(calculation[rowVal])}</TableCell>
                </React.Fragment>);
            className = ["profit_sum", "other_cost_total"].indexOf(rowName) > -1 ? "": "indent";
            className = rowVal === "compensation" ? "sum-row-highlight" : className;
        }
        // kustannuserien yhteensä-rivi
        if (props.tableData.batchType) {
            cellContent =  (<React.Fragment >
                                <TableCell align="right" colSpan={3} ></TableCell>
                                <TableCell align="right" >{functions.numberFormat(calculation[rowVal])}</TableCell>
                            </React.Fragment>) ;
                className = "sum-row";
        }
        // vertailutiedot
        if (props.tableData.tableName === "comparison") {
            const comparisonItem = constants.comparisonMapping[rowVal];
            const compVal = context.comparisonData ? functions.numberFormat(context.comparisonData[comparisonItem].firstQuartileValue) : "-";
            cellContent =<React.Fragment>
                    <TableCell align="right" >{functions.numberFormat(calculation[rowVal])}</TableCell>
                    <TableCell align="right" >{compVal}</TableCell>
                    </React.Fragment>
        }
        const info =  props.tableData.tableName === "subsidy_sum" ? infos.find(i => i.row === rowName ) : null;

        return (
            <TableRow key={"tr-"+rowVal} hover={true} className={className}>
                 <TableCell className="row-name" > 
                    { t(rowName)}
                    { info &&
                        <IconButton
                            onClick={e => functions.functionErrorBoundary(e,() => { handleIconClicks(e, info.index) })}
                            title={t(info.text)}
                            size="large">
                            <InfoIcon  color="primary"/> 
                        </IconButton>}
                 </TableCell>
                 { props.tableData.tableName === "subsidy_sum" && 
                    <TableCell align="left"> 
                        {info && showInfo[info.index] && <i>{t(info.text)}</i>} 
                    </TableCell>
                 }
                 {cellContent}
                 {unitText.length > 0 && <TableCell size="small" align="left" style={customColumnStyle2}>{unitText}</TableCell>}
            </TableRow>
        );
    }

    const generateBatchName = (item) => {
        var nameKeys = ["", "seed", "fertilizer", "pesticide", "other"];
        return item.generateName
            ? t(nameKeys[item.cost_type]) + " " + (item.batchNr || '')
            : item.name;
    }
    // Kustannusrivi: nimi (syötekenttä tai teksti), poisto-nappi, syötekentät e/yk. ja yks./ha sekä e/ha
    const renderCostBatchRow = (item, id) => {
        return (
            <TableRow key={"cb-"+id+item.id} hover={true}>
                <TableCell style={customColumnStyle1} >
                    {   !item.isDefault  &&
                            <TextField 
                            inputProps={{ style: {textAlign: "left", width: "100%" },  maxLength: 20}}
                            type="text" variant="outlined" size="small"
                            onChange={e => functions.functionErrorBoundary(e, () => { context.setName(calculation, item, e.target.value) }) }
                            value={generateBatchName(item)}> 
                        </TextField>
                    }
                    {item.isDefault && t(item.name)}
                </TableCell>
                <TableCell style={customColumnStyle2} align="left">
                    {!item.isDefault &&  
                        <IconButton
                            tabIndex={-1}
                            className="delete-button"
                            aria-label="Delete"
                            title={t("delete")}
                            onClick={e => functions.functionErrorBoundary(e, () => { context.deleteCostItem(calculation, item.id) }) }
                            size="large">
                            <DeleteIcon />
                        </IconButton>}
                </TableCell>
                <TableCell align="right" >
                    <NumberInput value={item.price} name="price" onBlur={(e) =>context.setBatchData(e, item,calculation)}/> 
                </TableCell> 
                <TableCell align="right" >
                     <NumberInput value={item.amount} name="amount" onBlur={(e) =>context.setBatchData(e, item,calculation)}/> 
                </TableCell>
                <TableCell align="right" style={customColumnStyle3}>
                    <NumberInput value={item.price_per_hectare} name="price_per_hectare" onBlur={(e) =>context.setBatchData(e, item, calculation)}/>
                </TableCell>
            </TableRow>
        );
    }

    const renderAddButton = () => {
        return <TableRow>
                    <TableCell colSpan={5} >
                        <IconButton color="primary" title={t("add")}  size="small" aria-label="Add" 
                            onClick={e => functions.functionErrorBoundary(e, () => { context.addBatchItem(calculation, props.tableData.batchType, t) }) }>
                            <AddBoxOutlinedIcon/>
                        </IconButton>
                    </TableCell>
                </TableRow>
    }
    return (
        <TableContainer>
          <Table  aria-label="data table" size="small" >
              {props.tableData.headers &&
                <TableHead className="table-header" >
                    <TableRow>
                        {props.tableData.headers.map((header,i) => <TableCell key={"header-"+props.tableData.tableName+i} 
                        align={i===0 ? "left" : "right"}
                        >{t(header)}</TableCell> )}
                    </TableRow>
                </TableHead>}   
              <TableBody>
                    {props.tableData.batchType &&
                        <React.Fragment>
                            {calculation.costBatches.filter(c=> c.cost_type === props.tableData.batchType).map((item, i) => (  renderCostBatchRow(item, props.tableData.batchType)))}                            
                            {renderAddButton()}
                            {renderRow(0)}
                        </React.Fragment>}
                    {!props.tableData.batchType &&
                        <React.Fragment>
                            {props.tableData.rows.map((rowVal, i) => ( renderRow(i)))}
                        </React.Fragment>}
                </TableBody> 
          </Table>
        </TableContainer>
    );
  }