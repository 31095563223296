let seq = require('seq-logging');

let logger = new seq.Logger({ 
  serverUrl: process.env.REACT_APP_SEQ_URL, 
  apiKey: process.env.REACT_APP_SEQ_KEY });


 const getDefaultInfo = () => {
   return {Application: "mtech.vyrcalc.web", Level: "ERROR", Time: new Date().toISOString(),   Message: "An error occurred"};
 }
 
 export const logError = (error, errorInfo) => {
   if (typeof error === 'string') {
    emit(getDefaultInfo(), error); 
    console.log("logError",error);
   }
   else { 
     var errorData = {
       ExceptionType: error.name,
       ExceptionStackTrace: error.stack,
       Exception: error.message,
       Message: `Exception of type ${error.name} occurred`,
       ExceptionData: errorInfo
     }; 
     var data =  {...getDefaultInfo(), ...errorData };
     emit(data, data.Message);
     console.log("logError", error, errorInfo);
   }
 }
 
 export const logApiError = (errorData) => {
   var data =  {...getDefaultInfo(), ...errorData  };
   emit(data, data.Message);
   console.log("logApiError", errorData);
 }

 const emit = (data, messageTemplate) =>  {
  logger.emit({ level: 'ERROR', messageTemplate: messageTemplate, properties: data });
 }