import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from "react-i18next";
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import DataTable from './table';
import * as constants from './constants';
import DropDown from './dropDown';
import { CalculationContext } from './calculationProvider';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import * as functions from './functions';

const PREFIX = 'calculator';

const classes = {
    seedInfoVisible: `${PREFIX}-seedInfoVisible`,
    seedInfoHidden: `${PREFIX}-seedInfoHidden`
};

const StyledGrid = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.seedInfoVisible}`]: {
        visibility: "visible",
        flex:1
    },

    [`& .${classes.seedInfoHidden}`]: {
        visibility: "hidden",
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            display: "none"
          }
    }
}));

export default function Calculator() {

    const [showSeedInfo, setShowSeedInfo] = useState(false);
    const context = React.useContext(CalculationContext);
    const calculation = context.calculation;

    const { t } = useTranslation();
    const sections = [
            {tableName:"harvest", rows: ["plant", "harvest_target", "est_price", "harvest_profit"], units: ["", "kg_hectare", "e_ton", "e_hectare_sum" ]}, 
            {tableName:"subsidy_sum", headers: ["","", "e_hectare"], closed: true,
            rows: ["subsidy_area", "subsidy", "compensation_nature", "compensation_environment", "compensation_plant", "subsidy_sum"],
            rowNames: ["subsidy_area", "subsidy", "compensation_nature", "compensation_environment", "compensation_plant", "sum"]},
            {tableName:"seed_cost", className: "section-seed-cost panel",
                rows: ["seeds_sum"], headers: ["", "", "e_unit", "unit_hectare", "e_hectare"], batchType: constants.BatchType.seed, rowNames:["sum"]},
            {tableName:"fertilizers", rows: ["fertilizer_sum"], headers: ["", "", "e_unit", "unit_hectare", "e_hectare"],batchType: constants.BatchType.fertilizer, rowNames:["sum"]}, 
            {tableName:"pesticides", rows: ["pesticide_sum"], headers: ["", "", "e_unit", "unit_hectare", "e_hectare"], batchType: constants.BatchType.pesticide, rowNames:["sum"]}, 
            {tableName:"other_cost", rows: ["other_sum"],  headers: ["", "", "e_unit", "unit_hectare", "e_hectare"], batchType: constants.BatchType.other, rowNames:["sum"]}, 
            {tableName: "results", className: "panel-highlight1  panel",
                rows: ["profit_sum", "harvest_profit", "subsidy_sum", "other_cost_total", "seeds_sum", "fertilizer_sum", "pesticide_sum", "other_sum", "compensation"],
                rowNames: ["profit_sum", "harvest_profit", "subsidy_sum", "other_cost_total", "seed_cost", "fertilizers", "pesticides", "other_cost", "compensation"],
                headers:["", "e_ton", "e_hectare"]
        },
            {tableName:"comparison", className: "panel-highlight2 panel",
                rows: ["harvest_target", "fertilizer_sum", "pesticide_sum","other_cost_total"], 
                rowNames: ["harvest_amount","fert_cost", "pesticide_cost", "other_cost_total"],
                units: ["kg_hectare", "e_hectare", "e_hectare", "e_hectare" ],
                headers:["", "calculation", "entire_country", ""]}
         ];

    const handleIconClicks = (event) => {
        event.stopPropagation();
        var val = showSeedInfo;
        setShowSeedInfo(!val);
    }

    const handleLinkClick = (event) => {
        event.stopPropagation();
        window.open("https://www.vyr.fi/fin/siemenlaskuri/",'_blank')
    }
    const getSeedHeader = () => {
        return ( <React.Fragment>
                    <p className={showSeedInfo ? classes.seedInfoVisible : classes.seedInfoHidden}>
                        <i>{t("info_seed")} </i>
                    </p>
                    <p className="link" onClick={e => functions.functionErrorBoundary(e, handleLinkClick)}>{t("seed_calculator")}</p>
            </React.Fragment>);
    } 

    var plantNameKey = Object.keys(constants.plants).find(key => constants.plants[key]=== calculation.plant);
    return (
        <StyledGrid container spacing={2}>  
            <Grid item xs={12} md={12}  id="mobile-info"><i>{t("mobile_info")}</i></Grid>
            {sections.map((section, i) => (
                <Grid item  xs={12} key={"grid"+section.tableName} > 
             <Paper elevation={1}>
                    <Accordion  defaultExpanded={section.closed !== true} className={section.className ? section.className : "panel"} >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"> 

                                        <Typography  className="section-header" color="primary">  
                                                {t(section.tableName) + " " + (section.tableName === "results" ? t(plantNameKey) : "" )} 
                                                {section.tableName === "seed_cost" && 
                                                    <IconButton
                                                        onClick={(e) => {functions.functionErrorBoundary(e,handleIconClicks)} }
                                                        title={t("info_seed")}
                                                        size="large">
                                                        <InfoIcon color="primary"/> 
                                                    </IconButton>     
                                                }     
                                        </Typography>
                                        {section.tableName === "seed_cost" &&  getSeedHeader()}
                                       
                        </AccordionSummary>

                        <AccordionDetails >
                            <Grid container  spacing={2}>  
                                { section.tableName === "comparison" &&                             
                                    <Grid item xs={12}>
                                        <Grid container spacing={1} alignItems="center">  
                                            <Grid item xs={5} sm={2}>{t("plant")}</Grid>
                                            <Grid item xs={7} sm={4}><DropDown name="comparison_plant"/></Grid>
                                            <Grid item xs={5} sm={2}>{t("year")}</Grid>
                                            <Grid item xs={7} sm={3}><DropDown name="comparison_year"/></Grid>
                                            {context.isLoading &&<Grid item xs={12} sm={1}><CircularProgress size={30} /></Grid>}
                                        </Grid>
                                    </Grid>                                 
                                }
                                <Grid item xs={12}>
                                    <DataTable  tableData={section}/>
                                </Grid>     
                                { section.tableName === "comparison" &&
                                    <Grid item xs={12}>
                                        <i>{t("comparison_description")}</i>
                                    </Grid>
                                }                                 
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    </Paper>
                </Grid> 
            ))
        }
        </StyledGrid>
    );
}   





