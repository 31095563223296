import React from 'react'
import * as functions from './functions';
import TextField from '@mui/material/TextField';


class NumberInput extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value: props.value,
            isChanging: false
        }

    }  
    handleChange = e => {
        const amount = e.target.value;
        // Numeroita 8 ja desimaaleja 2. Desimaalierottimena pilkku tai piste
        if (!amount || amount.match(/^\d{1,8}(\.\d{0,2})?$/) || amount.match(/^\d{1,8}(,\d{0,2})?$/)) {
            this.setState({ value: e.target.value, isChanging: true });
        }
    };

    handleBlur = e => {
        let val = this.state.value === null ? null : this.state.value.toString().replace(",", ".");
        // Jos kentässä vain käydään eikä kirjoiteta, laitetaan arvoksi parametrina saatu arvo, jotta lasketuksessa saadut uudet luvut näkyvät.
        if (!this.state.isChanging)
            val = this.props.value;
        
        val = isNaN(parseFloat(val)) ? null : parseFloat(val);
        // Jos kentän arvoa muutettu, tarkistetaan laskelma.
        if (this.state.isChanging)
            this.props.onBlur([this.props.name, val]);
        this.setState({isChanging: false, value: val});
    }
    
    render() {
        var value = this.state.isChanging ? this.state.value  : this.props.value;
        var val = value === null ? '' : value;
        return (
            <TextField style = {{maxWidth: 150}} className="number-input"
                inputProps={{  style: {textAlign: "right" }}}
                type="text"
                onBlur={e => functions.functionErrorBoundary(e, this.handleBlur)} variant="outlined" size="small"
                onChange={e => functions.functionErrorBoundary(e, this.handleChange)}
                value={val}> 
            </TextField>

        );
    }

}
export default NumberInput;