import React from 'react'
import { withTranslation } from 'react-i18next';
import * as logger from './logger';


class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };

    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      console.log("error boundary ", error, errorInfo);
      logger.logError(error, errorInfo);
    }
  
    render() {
      const { t } = this.props;
      if (this.state.hasError) {
        return <div className="container"><h3>{t("render_fail")}</h3></div>;
      }
  
      return this.props.children; 
    }
  }

  export default withTranslation()(ErrorBoundary)