import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import  { CalculationContext } from './calculationProvider';
import { useTranslation } from "react-i18next";
import * as functions from './functions';

export default function AlertDialog() {
    const { t } = useTranslation();
    const context = React.useContext(CalculationContext);
    const calculation = context.calculation;

    const [currentPlant, setcurrentPlant] = useState(calculation.plant);

    const handleClose = (clearData) => {
        if (clearData)
            context.clearCalculation(calculation);
        setcurrentPlant(calculation.plant);
  };
  return (
      <Dialog
        open={currentPlant !== calculation.plant}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("notification_topic")}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("notification_Change_Plant")}
          </DialogContentText>
        </DialogContent>
        <DialogActions> 
         
          
          <Button onClick={e => functions.functionErrorBoundary(false, handleClose)} variant="contained" color="inherit" >
          {t("notification_No")}
          </Button>
          <Button onClick={e => functions.functionErrorBoundary(true, handleClose)} color="primary" className="main-theme-text" variant="contained" >
            {t("notification_Yes")}
          </Button>
        </DialogActions>
      </Dialog>

  );
}