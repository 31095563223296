import i18next from "i18next";
import {  initReactI18next } from "react-i18next";

const resources = {
    fi: {
        translation: {
        app_name: "Tuotantokustannuslaskuri",
        kg_hectare: "kg/ha",
        unit_hectare: "yks./ha",
        e_ton: "€/tn",
        e_unit: "€/yks.",
        e_hectare: "€/ha",
        e_hectare_sum: "€/ha yht.",
        harvest: "Viljelykasvi ja sato",
        plant: "Viljelykasvi",
        harvest_target: "Satotavoite",
        est_price: "Arvioitu sadon hinta/sopimushinta",
        harvest_profit: "Satotuotto",
        subsidy_sum: "Tuet ja palkkiot",
        subsidy_area: "Tukialue",
        subsidy_a: "A/B",
        subsidy_c: "C",
        subsidy: "Perustulotuki",
        compensation_nature: "Luonnonhaittakorvaus",
        compensation_environment: "Ympäristökorvaus",
        compensation_plant: "Muut kasvinviljelyn pinta-alatuet",
        seed_cost: "Siemenkustannus",
        seed: "Siemen",
        seed_calculator: "VYR Siemenlaskuri",
        fertilizers: "Lannoitteet",
        fertilizer: "Lannoite",
        pesticides: "Kasvinsuojeluaineet",
        pesticide: "Valmiste",
        other_cost: "Muut kustannukset",
        fuels: "Poltto- ja voiteluaineet",
        drying: "Kuivaus",
        other: "Muut",
	results: "Yhteenveto:",
	other_cost_total: "Muuttuvat kustannukset yhteensä",
        profit: "Sadosta saadut tuotot yhteensä",
        compensation: "Työlle ja kiinteille kuluille jäävä korvaus",
        comparison: "Vertailutiedot ProAgrian Lohkotietopankista",
        year: "Vuosi",
        comparison_description: "*Kannattavuudeltaan paras neljännes",
        fert_cost: "Lannoituskustannukset",
        pesticide_cost: "Kasvinsuojelukustannukset",
        harvest_amount: "Sadon määrä",
        entire_country: "Koko maa*",
        calculation: "Oma laskelma",
	sum: "Yhteensä",
        none:"",
        //Viljelykasvit:
        winter_wheat: "Syysvehnä",
        spring_wheat: "Kevätvehnä",
        rye: "Ruis",
        feed_barley: "Rehuohra",
        malting_barley: "Mallasohra",
        oat: "Kaura",
        turnip_rape_A: "Syysrypsi",
	turnip_rape_S: "Kevätrypsi",
        rape_A: "Syysrapsi",
        rape_S: "Kevätrapsi",
        horse_bean: "Härkäpapu",
        pea: "Rehuherne",
        tech_execution: "Tekninen toteutus:",
	notification_topic: "Kasvin vaihto",
	notification_Change_Plant: "Olet vaihtamassa kasvia. Haluatko tyhjentää syöttämäsi tiedot?",
	notification_Yes: "Kyllä, tyhjennä tiedot",
	notification_No: "Ei, säilytä tiedot",
	profit_sum: "Sadosta saadut tuotot yhteensä",
        mobile_info: "Suosittelemme käyttämään laskuria vaakatasossa kapealla näytöllä",
        add:"Lisää rivi",
        delete: "Poista rivi",
	info_seed: "Arvioitu siemenkustannus. Voit käyttää arvion tekemiseen apuna VYR:n siemenlaskuria.",
	info_evironment: "Ympäristökorvaus on 54 €/ha + valitsemasi lohkokohtaiset toimenpiteet.",	
	info_plantsubsidy: "Peltokasvipalkkiota maksetaan tällä hetkellä vain rukiille, öljykasveille ja palkokasveille.",
	notification_no_data: "Valitulle kasvi-vuosi -yhdistelmälle ei löydy Lohkotietopankista tarpeeksi laskelmia.",
	select: "Valitse",
	get_fail: "Tietojen haku epäonnistui!",
 	render_fail: "Nyt tapahtui jotain odottamatonta, päivitä sivu."
        },
    },
    sv: {
    translation: {
        app_name: "Produktionskostnadsräknare",
        kg_hectare: "kg/ha",
        unit_hectare: "enh./ha",
        e_ton: "€/ton",
        e_unit: "€/enh.",
        e_hectare: "€/ha",
        e_hectare_sum: "€/ha sammanl.",
        harvest: "Odlingsväxt och skörd",
        plant: "Odlingsväxt",
        harvest_target: "Skördemålsättning",
        est_price: "Skördens uppskattade pris/avtalspris",
        harvest_profit: "Skördeintäkt",
        subsidy_sum: "Stöd och premier",
        subsidy_area: "Stödregion",
        subsidy_a: "A/B",
        subsidy_c: "C",
        subsidy: "Grundläggande inkomststöd",
        compensation_nature: "Kompensationsbidrag",
        compensation_environment: "Miljöersättning",
        compensation_plant: "Andra växtodlings arealstöd",
        seed_cost: "Utsädeskostnad",
        seed: "Utsäde",
        seed_calculator: "VYR Utsädesräknare",
        fertilizers: "Gödselmedel",
        fertilizer: "Gödselmedel",
        pesticides: "Växtskyddsmedel",
        pesticide: "Preparat",
        other_cost: "Övriga kostnader",
        fuels: "Bränsle och smörjmedel",
        drying: "Torkning",
        other: "Övrigt",
        results: "Sammandrag:",
        other_cost_total: "Rörliga kostnader sammanlagt",
        profit: "Intäkter från skörden",
        compensation: "Ersättning för arbete och fasta kostnader",
        comparison: "Jämförande uppgifter från ProAgrias Skiftesdatabank",
        year: "År",
        comparison_description: "*Bästa kvartalet enligt lönsamheten",
        fert_cost: "Gödslingskostnader",
        pesticide_cost: "Växtskyddskostnader",
        harvest_amount: "Skördemängd",
        entire_country: "Hela landet*",
        calculation: "Egen kalkyl",
        sum: "Sammanlagt",
        none:"",
        //Viljelykasvit:
        winter_wheat: "Höstvete",
        spring_wheat: "Vårvete",
        rye: "Råg",
        feed_barley: "Foderkorn",
        malting_barley: "Maltkorn",
        oat: "Havre",
        turnip_rape_A: "Höstrybs",
        turnip_rape_S: "Vårrybs",
        rape_A: "Höstraps",
        rape_S: "Vårraps",
        horse_bean: "Bondböna",
        pea: "Foderärt",
        tech_execution: "Tekniskt utförande:",
        notification_topic: "Byte av växt",
        notification_Change_Plant: "Du planerar att byta växt. Vill du radera de uppgifter du matat in?",
        notification_Yes: "Ja, radera uppgifterna",
        notification_No: "Nej, spara uppgifterna",
        profit_sum: "Intäkter från skörden sammanlagt",
        mobile_info: "Vi rekommenderar att du använder räknaren i vågrätt läge på en smal display",
        add:"Lägg till raden",
        delete: "Radera raden",
        info_seed: "Uppskattad utsädeskostnad. Du kan använda VYR:s utsädesräknare för att uppskatta kostnaden.",
        info_evironment: "Miljöersättningen är 54 €/ha + valda skiftesvisa åtgärder.",
	info_plantsubsidy: "Birdrag för jordbruksgrödor betalas för tillfället endast åt råg, oljeväxter och baljväxter.",
        notification_no_data: "För den växt-år-kombination du valt hittas inte tillräckligt många kalkyler i skiftesdatabanken.",
        select: "Välj",
        get_fail: "Datasökningen misslyckades!",
        render_fail: "Nu skedde något oväntat, uppdatera sidan."
        },
    },
};
i18next
      .use(initReactI18next)
      .init({
        resources,
        lng: "fi",
        interpolation: {
          escapeValue: false,
        },
      });
    export default i18next;