import React from 'react';
import { styled } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import  { CalculationContext } from './calculationProvider';
import { useTranslation } from "react-i18next";
import * as functions from './functions';

const PREFIX = 'message';

const classes = {
  root: `${PREFIX}-root`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    },
  }
}));

function AlertFunc(props) {
  return <Alert icon={false} elevation={6} variant="filled" {...props} />;
}

export default function Message() {

    const context = React.useContext(CalculationContext);

    const { t } = useTranslation();
    const handleClose = () => {
            context.clearMessage();
    };

    if (context.message === null) return null;
    else  return (
      <Root className={classes.root}>
      <Snackbar 
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={context.message !== null} autoHideDuration={10000} onClose={handleClose}>
          <div> 
            <AlertFunc onClose={e => functions.functionErrorBoundary(e, handleClose)} severity={context.message.severity}>
                {t(context.message.key)}
            </AlertFunc>
          </div>
      </Snackbar>
      </Root>
    );
}