//import * as constants from './constants';
import * as logger from './logger';

export function roundNumber(number, precision) {
    return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision);
  }
  
export const numberFormat = (value, decimals) => {
  var dec = decimals === undefined ? 2 : decimals;
  if (value === undefined || value === null)
  return "";
  return new Intl.NumberFormat('fi-FI', {
    style: 'decimal',
    minimumFractionDigits: dec,
    maximumFractionDigits: dec
  }).format(value);
};

export const getEuroPerTon = (value, calculation, decimals) => {

    if (value === null || value === undefined || (calculation.harvest_target||0) === 0)
        return null;
    return numberFormat(value / ((calculation.harvest_target||0)/1000), decimals);
}

export const functionErrorBoundary = (event, callback) => {
    try {
        callback(event);
    }
    catch (error) {
        logger.logError(error);
    }
}

export const setSubsidyData = (calculation) =>  {
/*  MTK-5: toistaiseksi poistettu käytöstä.
  var val = {subsidy:null, compensation_plant:null, compensation_nature: null};

  switch(calculation.subsidy_area) {
      case constants.subsidyArea.subsidy_a:
          switch (calculation.plant) {
              case constants.plants.feed_barley: 
              case constants.plants.malting_barley: 
              case constants.plants.oat: // rehuohra, mallasohra, kaura
                  val = {subsidy:193, compensation_plant:0, compensation_nature: 217};
                  break;
              case constants.plants.spring_wheat:
              case constants.plants.winter_wheat:              // // syysvehnä ja kevätvehnä
                  val = {subsidy:193, compensation_plant:0, compensation_nature: 217};
                  break;
              case constants.plants.rye:            // ruis
                  val = {subsidy:193, compensation_plant:74, compensation_nature: 217};
                  break;
              default:                    //  rypsi, rapsi,  rehuherne, härkäpapu
                  val = {subsidy:193, compensation_plant:113, compensation_nature: 217};
          }
          break;
      case constants.subsidyArea.subsidy_c:
          switch (calculation.plant) {
              case constants.plants.feed_barley:
              case constants.plants.malting_barley:
              case constants.plants.oat: // rehuohra, mallasohra, kaura
                  val = {subsidy:168, compensation_plant:0, compensation_nature: 242};
                  break;
              case constants.plants.spring_wheat:
              case constants.plants.winter_wheat:  // syysvehnä ja kevätvehnä
                  val = {subsidy:168, compensation_plant:0, compensation_nature: 242};
                  break;
              case constants.plants.rye:                        // ruis
                  val = {subsidy:168, compensation_plant:74, compensation_nature: 242};
                  break;
              case constants.plants.horse_bean:
              case constants.plants.pea:      // rehuherne, härkäpapu
                  val = {subsidy:168, compensation_plant:113, compensation_nature: 242};
                  break;
              default:                                // rypsi, rapsi 
                  val = {subsidy:168, compensation_plant:113, compensation_nature: 242};
          }
          break;
      default:
  }
  
  calculation.subsidy = val.subsidy
  calculation.compensation_nature = val.compensation_nature;
  calculation.compensation_plant = val.compensation_plant;
  */
}
