import React from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { createContext } from 'react';
import * as functions from './functions';
import * as c from './constants';
import * as api from './api';
const CalculationContext = createContext(undefined);
const storageKeyCalc ="grain_prod_cost_calculation";


function roundNumber(number, precision) {
  return functions.roundNumber(number, precision);
}

function getSum(calculation, batch_type) {
  return calculation.costBatches.filter(b => b.cost_type === batch_type)
    .reduce((sum1, item) => sum1 + (item.price_per_hectare || 0),0)
}

class CalculationProvider extends React.Component{
  constructor(props) {

    super(props);

     const calculateValues = (calculation) =>  {
      // Satotuotto
      calculation.harvest_profit = roundNumber((calculation.harvest_target||0) / 1000 * (calculation.est_price||0), 2);
    
      // Tukien ja palkkioiden summa
      calculation.subsidy_sum = roundNumber(
        (calculation.subsidy ||0)
        + (calculation.compensation_nature ||0)
        + (calculation.compensation_environment||0) 
        + (calculation.compensation_plant||0), 2);
    
      // Satotuottojen ja tukien summa
      calculation.profit_sum = roundNumber(calculation.harvest_profit + calculation.subsidy_sum ,2);
    
      // Eri kustannusten summmat
      calculation.seeds_sum = getSum(calculation, c.BatchType.seed);
      calculation.fertilizer_sum = getSum(calculation, c.BatchType.fertilizer);
      calculation.pesticide_sum = getSum(calculation, c.BatchType.pesticide);
      calculation.other_sum = getSum(calculation, c.BatchType.other);
    
      // Muuttuvat kustannukset yhteensä e/ha
      calculation.other_cost_total = calculation.seeds_sum  + calculation.fertilizer_sum + calculation.pesticide_sum + calculation.other_sum;
    
      // Satotuotot + Tuet ja palkkiot - Muuttuvat kustannukset yhteensä = Sadon vähimmäishintavaatimus, e/ha = työlle ja kiinteille kuluille jäävä korvaus
      calculation.compensation =  (calculation.profit_sum||0) - (calculation.other_cost_total||0);

      localStorage.setItem(storageKeyCalc, JSON.stringify(calculation));
    }
    
    const setBatchName = (calc, item, name) => {   
      var copy = Object.assign({}, calc);
      var batch = copy.costBatches.find(c => c.id === item.id);
      batch.name = name;
      batch.generateName = false;
      localStorage.setItem(storageKeyCalc, JSON.stringify(copy));
      this.setState({...this.state, calculation: copy});     

    }

    const addCostItem = (calc, itemType, t) => {
      var copy = Object.assign({}, calc);
      var batch = Object.assign({}, c.defaultBatch);
      
      batch.cost_type = itemType;
      batch.batchNr = calc.costBatches.filter(b => b.cost_type === itemType).length + 1;
      batch.id = calc.costBatches.length === 0 ? 1 : calc.costBatches.reduce((prev, current) => (prev.id > current.id) ? prev : current).id +1 ;
      copy.costBatches.push(batch);

      calculateValues(copy);
      this.setState({...this.state, calculation: copy});
    }

    const deleteCostItem = (calc, id) => {
      var copy = Object.assign({}, calc);
      copy.costBatches = copy.costBatches.filter(c => c.id !== id);
      calculateValues(copy);
      this.setState({...this.state, calculation: copy});
    }

    const setBatchData =(parameters, item, calc) =>
    {
      var copy = Object.assign({}, calc);
      var batch = copy.costBatches.find(c => c.cost_type === item.cost_type && c.id === item.id);
      const propName = parameters[0];
      batch[propName] = parameters[1];

      // Lasketaan e/ha, jos annettu määrä tai hinta
      if (propName === "amount" || propName === "price")
        batch.price_per_hectare =  roundNumber( (batch.amount||0) 
                    * (batch.price ||0),2);

      
      if (propName === "price_per_hectare")
      { 
        // Lasketaan määrä, jos e/ha annettu ja löytyy hinta
        if (batch.amount === null && batch.price > 0)
          batch.amount = roundNumber( (batch.price_per_hectare||0) / batch.price,2);

        // Lasketaan hinta, jos e/ha annettu ja löytyy  määrä
        else if (batch.price === null && batch.amount > 0)
          batch.price = roundNumber( (batch.price_per_hectare||0) / batch.amount,2);

        else {
          batch.amount = null;
          batch.price = null;
        }
      }
      calculateValues(copy);
      this.setState({...this.state, calculation: copy});

    }

    const handleInputChange = (parameters, calc) => 
    {
      var copy = Object.assign({}, calc);
      const propName = parameters[0];
      copy[propName] = parameters[1];

      // Jos kasvi muuttui, vaihdetaan sama myös vertailukasville.
      if (propName === "plant")
        copy.comparison_plant = parameters[1];

      // Jos kasvi tai tukiealue muuttui, asetetaan tukitiedot.
      if (propName === "plant" || propName === "subsidy_area") 
        functions.setSubsidyData(copy);

     calculateValues(copy);
       // Jos kasvi tai vuosi muuttui, haetaan vertailutiedot uudelleen.
     var getCompData = ["comparison_plant" , "comparison_year", "plant"].indexOf(propName) > -1
                    && copy.comparison_year != null;

      this.setState({...this.state, calculation: copy, isLoading: getCompData});

      if (getCompData) 
        this.getComparisonData(copy);
       
    }

     
  

  const clearCalculation = (calc) => {
      var defaultCalc = getDefaultCalc();
      defaultCalc.plant = calc.plant;
      defaultCalc.comparison_plant = calc.comparison_plant;
      defaultCalc.comparison_year = calc.comparison_year;
      functions.setSubsidyData(defaultCalc);
      calculateValues(defaultCalc);
      this.setState({...this.state, calculation: defaultCalc});
    }

    const getDefaultCalc = () => {
      const defaultCalc = c.defaultCalc
      var calc = cloneDeep(defaultCalc);
      functions.setSubsidyData(calc);
      calculateValues(calc);
      return calc;
    }
    const initCalculation = () => {
        const localCalc = localStorage.getItem(storageKeyCalc);
        const defaultCalc = getDefaultCalc();
        var calc = localCalc ? JSON.parse(localCalc) : defaultCalc;

        // lisätään oletuslaskelmasta mahdolliset uudet ominaisuudet
        if (localCalc)
          calc = {...defaultCalc,  ...calc}

        // Vanhalla laskelmalla voi olla liian vanha vertailuvuosi. Korjataan oletusarvoon.
        if (calc.comparison_year <= new Date().getFullYear()-5 )
          calc.comparison_year = defaultCalc.comparison_year;

        calculateValues(calc);
        return calc;
    }

    const clearMessage = () => {
      this.setState({...this.state, message: null});
    }
 
    var calc = initCalculation();
    var isLoading = calc.comparison_year !== null;
    this.state =  { 
      calculation:calc,
      comparisonData:null,
      setData: handleInputChange,
      setBatchData: setBatchData,
      addBatchItem: addCostItem,
      deleteCostItem: deleteCostItem,
      setName: setBatchName,
      clearCalculation: clearCalculation,
      clearMessage: clearMessage,
      message: null,
      isLoading: isLoading
    };  
    
    this.getComparisonData = this.getComparisonData.bind(this);
  } // Constructor

  componentDidMount() {  
    if (this.state.isLoading)
      this.getComparisonData(this.state.calculation);
  }

  getComparisonData (calc)  {
    api.loadComparisonData(calc, (data, success) => {

        var message = null;
        if (!success)
          message = {key: "get_fail", severity: "error"};
         
        if (data != null && (data.anyDataExists === false || data.fertilizationCost.firstQuartileValue === -999999)  ) {
          message = {key: "notification_no_data", severity: "warning"};
          data = null;
        }
        this.setState({...this.state, calculation: calc, isLoading: false, comparisonData: data, message: message}); 
  });
}
   render() { return (
      <CalculationContext.Provider value={this.state}>
        {this.props.children}
      </CalculationContext.Provider>
    );
   }
}

  export { CalculationProvider, CalculationContext };