import React from 'react';
import { styled } from '@mui/material/styles';
import mtechImage from '../Images/MTech_Logo2.svg';
import logo from '../Images/MTK_LA01_perus_____V___CMYK.svg';
import { useTranslation } from "react-i18next";

const PREFIX = 'footer';

const classes = {
  footer: `${PREFIX}-footer`,
  logo1: `${PREFIX}-logo1`,
  logo2: `${PREFIX}-logo2`,
  image1: `${PREFIX}-image1`,
  image2: `${PREFIX}-image2`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.footer}`]: {
      padding:20
  },

  [`& .${classes.logo1}`]: {
    float:"left"
  },

  [`& .${classes.logo2}`]: {
      textAlign:"right"
    },

  [`& .${classes.image1}`]: {
    height:"100px",
    marginBottom: 20
  },

  [`& .${classes.image2}`]: {
    maxWidth:"125px",
    marginBottom: 20
  }
}));

export default function Footer() {

  const { t } = useTranslation();
  return (
    <Root className={classes.footer}>    
       
        <div className={classes.logo1}>
            <div>{t("tech_execution")}</div> 
            <a href="https://www.mtech.fi/" target="_blank"  rel="noreferrer" >
                <img className={classes.image1} src={mtechImage} alt="Mtech Digital Solutions Oy" />
          </a>
        </div> 
        <div className={classes.logo2}>
            <a href="https://www.mtk.fi/" target="_blank"  rel="noreferrer" >
                <img className={classes.image2} src={logo} alt="MTK" />
          </a>
        </div>        
    </Root>
  );
}