import React from 'react';
import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTranslation } from "react-i18next";
import LanguageSelect from './languageSelect';
const PREFIX = 'header';

const classes = {
  langSelect: `${PREFIX}-langSelect`,
  langSelectMobile: `${PREFIX}-langSelectMobile`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.langSelect}`]: {
    [theme.breakpoints.down('sm')]: {
      display: "none"
    },
  },

  [`& .${classes.langSelectMobile}`]: {
      display: "none",
      [theme.breakpoints.down('sm')]: {
        padding:5,
        display: "block"
      }
  }
}));

export default function Header() {


    const { t } = useTranslation();

    return (
      <Root className="toolbar ">
        <AppBar position="static">
          <Toolbar>
          
            <Typography variant="h4" className="main-theme-text" style={{ flex: 1 }}>
            {t("app_name")}
            </Typography>
            <div className={classes.langSelect}> <LanguageSelect /></div>
          </Toolbar>

          <div className={classes.langSelectMobile}> <LanguageSelect/></div>
        </AppBar>          
      </Root>
    );
}