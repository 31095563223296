import * as constants from './constants';
import * as logger from './logger';
const RESPONSE_ERROR = "RaisedResponseError";

export const loadComparisonData = (calculation, callback) => {
   try {
      var plantParams = constants.plantMapping[calculation.comparison_plant];
      var params = "productionType=normal&language=fi&calcYear="+calculation.comparison_year;
      params += "&plants="+plantParams.plantIds.join(";");
    
      if (plantParams.product !== null)
        params += "&productId="+plantParams.product;

      let uri = process.env.REACT_APP_API_URL+'cost-of-production-report?'+params; 
      return fetch(uri, { 
          method: 'get', 
          headers: new Headers({
            'Content-Type': 'application/json; charset=utf-8'
          })
        })
        .then(e => checkResponse(e,"GET"))
        .then((jsonResponse) => { callback(jsonResponse, true);
        }).catch((error) => { 
          if (error && error.message !== RESPONSE_ERROR)
            logger.logApiError({Message: error, HttpMethod: "GET", RequestUri: uri});
          callback(null, false);
        });    
    }
    catch (error) {
      logger.logError(error);
      callback(null, false);
    }
   }

  const checkResponse = (response, method) => {  
    if (response.status === 200)
         return response.json()
     else {
       logger.logApiError({Message: "Fetch failed", CorrelationId: response.headers.get("x-correlation-id"), HttpMethod: method, RequestUri: response.url, HttpStatusCode: response.status});
       throw Error(RESPONSE_ERROR);
     }
}

  